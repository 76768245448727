import React from 'react'

import { type IconProps } from '@/assets/types'

export const Icons = {
  menu: (props: IconProps) => (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      width='28'
      height='16'
      viewBox='0 0 28 16'
      {...props}>
      <path
        d='M12.6667 14.3385C12.6667 13.7865 13.1147 13.3385 13.6667 13.3385H26.3334C26.8854 13.3385 27.3334 13.7865 27.3334 14.3385C27.3334 14.8905 26.8854 15.3385 26.3334 15.3385H13.6667C13.1147 15.3385 12.6667 14.8905 12.6667 14.3385ZM0.666748 7.67179C0.666748 7.11979 1.11475 6.67179 1.66675 6.67179H26.3334C26.8854 6.67179 27.3334 7.11979 27.3334 7.67179C27.3334 8.22379 26.8854 8.67179 26.3334 8.67179H1.66675C1.11475 8.67179 0.666748 8.22379 0.666748 7.67179ZM6.00008 1.00513C6.00008 0.453127 6.44808 0.00512695 7.00008 0.00512695H26.3334C26.8854 0.00512695 27.3334 0.453127 27.3334 1.00513C27.3334 1.55713 26.8854 2.00513 26.3334 2.00513H7.00008C6.44808 2.00513 6.00008 1.55713 6.00008 1.00513Z'
        fill='currentColor'
      />
    </svg>
  ),
  social: (props: IconProps) => (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      width='32'
      height='32'
      viewBox='0 0 32 32'
      {...props}>
      <path
        d='M28 12C25.792 12 24 13.7907 24 16C24 18.2093 25.792 20 28 20C30.208 20 32 18.2093 32 16C32 13.7907 30.208 12 28 12ZM8 24C5.79067 24 4 25.7907 4 28C4 30.2093 5.79067 32 8 32C10.208 32 12 30.2093 12 28C12 25.7907 10.208 24 8 24ZM12 4C12 6.20933 10.208 8 8 8C5.792 8 4 6.20933 4 4C4 1.79067 5.792 0 8 0C10.208 0 12 1.79067 12 4ZM14.1173 1.36133L14.6667 1.33333C20.3747 1.33333 25.308 4.6 27.7333 9.36133C26.7773 9.4 25.88 9.64133 25.0693 10.0427C22.996 6.43867 19.1147 4 14.6667 4C14.6667 3.06267 14.468 2.17067 14.1173 1.36133ZM27.7333 22.6387C25.308 27.4 20.3747 30.6667 14.6667 30.6667L14.1173 30.6387C14.468 29.8293 14.6667 28.9373 14.6667 28C19.1147 28 22.996 25.5613 25.0693 21.9573C25.88 22.36 26.7773 22.6013 27.7333 22.6387ZM2.52 24.22C0.929333 21.8747 0 19.0467 0 16C0 12.9533 0.929333 10.1253 2.52 7.78C3.04267 8.53467 3.72133 9.16533 4.50267 9.65067C3.34667 11.4947 2.66667 13.6667 2.66667 16C2.66667 18.3333 3.34667 20.5053 4.50267 22.3493C3.72133 22.8347 3.04133 23.4653 2.52 24.22Z'
        fill='currentColor'
      />
    </svg>
  ),
  facebook: (props: IconProps) => (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      width='32'
      height='32'
      viewBox='0 0 32 32'
      {...props}>
      <path
        d='M16,2.667A13.333,13.333,0,1,1,2.667,16,13.348,13.348,0,0,1,16,2.667ZM16,0A16,16,0,1,0,32,16,16,16,0,0,0,16,0ZM13.333,13.333H10.667V16h2.667v8h4V16H19.76L20,13.333H17.333V12.223c0-.637.128-.889.744-.889H20V8H16.795c-2.4,0-3.461,1.056-3.461,3.077v2.256Z'
        fill='currentColor'
      />
    </svg>
  ),
  instagram: (props: IconProps) => (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      width='32'
      height='32'
      viewBox='0 0 32 32'
      {...props}>
      <path
        d='M16,2.884c4.272,0,4.779.016,6.467.093,4.336.2,6.361,2.255,6.559,6.559.077,1.687.092,2.193.092,6.465s-.016,4.779-.092,6.465c-.2,4.3-2.219,6.361-6.559,6.559-1.688.077-2.192.093-6.467.093s-4.779-.016-6.465-.093c-4.347-.2-6.361-2.265-6.559-6.56C2.9,20.779,2.883,20.273,2.883,16s.017-4.777.093-6.465c.2-4.3,2.219-6.361,6.559-6.559C11.223,2.9,11.728,2.884,16,2.884ZM16,0c-4.345,0-4.889.019-6.6.1C3.593.363.364,3.587.1,9.4.019,11.111,0,11.655,0,16s.019,4.891.1,6.6C.363,28.408,3.587,31.637,9.4,31.9c1.708.077,2.252.1,6.6.1s4.891-.019,6.6-.1C28.4,31.637,31.64,28.413,31.9,22.6c.079-1.707.1-2.252.1-6.6s-.019-4.889-.1-6.6C31.643,3.6,28.415.364,22.6.1,20.891.019,20.345,0,16,0Zm0,7.784A8.217,8.217,0,1,0,24.216,16,8.216,8.216,0,0,0,16,7.784Zm0,13.549A5.333,5.333,0,1,1,21.333,16,5.333,5.333,0,0,1,16,21.333ZM24.541,5.54A1.92,1.92,0,1,0,26.46,7.46,1.921,1.921,0,0,0,24.541,5.54Z'
        fill='currentColor'
      />
    </svg>
  ),
  linkedin: (props: IconProps) => (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      width='32'
      height='32'
      viewBox='0 0 32 32'
      {...props}>
      <path
        d='M16,2.667A13.333,13.333,0,1,1,2.667,16,13.348,13.348,0,0,1,16,2.667ZM16,0A16,16,0,1,0,32,16,16,16,0,0,0,16,0ZM13.333,10.667A1.333,1.333,0,1,1,12,9.323,1.338,1.338,0,0,1,13.333,10.667Zm0,2.667H10.667v8h2.667Zm4,0H14.667v8h2.667V17.519c0-2.3,2.669-2.508,2.669,0v3.815h2.664V16.855c0-4.379-4.171-4.219-5.333-2.064V13.333Z'
        fill='currentColor'
      />
    </svg>
  )
}
