'use client'

import Link from 'next/link'
import { usePathname } from 'next/navigation'
import { useAuth } from '@clerk/nextjs'
import { DashboardIcon, GlobeIcon } from '@radix-ui/react-icons'

import { cn } from '@/lib/utils'

type Props = {
  isLive: boolean
}

export const AdminLink = ({ isLive }: Props) => {
  const pathname = usePathname()
  const isAdmin = pathname.startsWith('/dashboard')
  const { isLoaded, userId } = useAuth()
  const Component = isAdmin ? GlobeIcon : DashboardIcon

  return (
    isLoaded &&
    !!userId && (
      <Link href={isAdmin ? '/' : '/dashboard'}>
        <Component
          className={cn(
            'h-6 w-6',
            isLive
              ? 'text-green-600 hover:text-green-600/80'
              : 'text-orange-500 hover:text-orange-500/80'
          )}
        />
      </Link>
    )
  )
}
