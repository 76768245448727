import { clsx, type ClassValue } from 'clsx'
import { twMerge } from 'tailwind-merge'

export function cn(...inputs: ClassValue[]) {
  return twMerge(clsx(inputs))
}

export function sanitizeUrlString(text?: string) {
  return `/${text
    ?.trim()
    .normalize('NFD')
    .replace(/[^a-zA-Z0-9 ]/g, '')
    .replace(/\p{Diacritic}/gu, '')
    .replace(/[\s]+/g, '-')
    .replace(/[\-]$/, '')
    .toLowerCase()}`
}
