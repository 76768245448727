import {
  BackpackIcon,
  EnvelopeOpenIcon,
  ReaderIcon,
  ShadowInnerIcon
} from '@radix-ui/react-icons'

import { type WebsiteLink } from '@/config/types'

export const websiteLinks: WebsiteLink = [
  {
    title: 'Despre mine',
    path: '/despre-mine',
    icon: ShadowInnerIcon
  },
  {
    title: 'Servicii',
    path: '/servicii',
    icon: BackpackIcon
  },
  {
    title: 'Blog',
    path: '/blog',
    icon: ReaderIcon
  },
  {
    title: 'Contact',
    path: '/contact',
    icon: EnvelopeOpenIcon
  }
]

export const dashboardLinks: WebsiteLink = [
  {
    title: 'Identitate',
    path: '/dashboard/identity'
  },
  {
    title: 'Servicii',
    path: '/dashboard/services'
  },
  {
    title: 'Articole',
    path: '/dashboard/articles'
  },
  {
    title: 'Recomandări',
    path: '/dashboard/recommendations'
  }
]
